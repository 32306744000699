import React from "react";
import SEO from "../components/seo";
import Header from "../components/header";
import Section from "../components/section";
import Layout from "../components/layout";
import H1 from "../components/sectionHeading";
import ServiceBar from "../components/serviceBar";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

const Services = () => {
  return (
  <Layout>
    <SEO title="Checkout" path='checkout-cancel'/>
    
    <Header/>

    <Section className='cg-section-light' nohr>
      <Row>
        <Col>
          <H1>Cancelled</H1>
          <p>Your order was cancelled.</p>
          <p>
            Please use the buttons below to return to our <strong><Link to='services' className='dark'>Services</Link></strong> page or use 
            the <strong><a href='#contactForm' className='dark'>contact form</a></strong> to 
            let us know what&nbsp;you&nbsp;need.
          </p>
        </Col>
      </Row>
    </Section>
    
    <ServiceBar/>
    
  </Layout>
)};

export default Services;
